import { $t } from "/@/plugins/i18n";
const Layout = () => import("/@/layout/index.vue");

const fileRouter = {
  path: "/file",
  component: Layout,
  redirect: "/file/admin",
  meta: {
    icon: "file",
    title: $t("文件"),
    rank: 33,
    roles: ["file"]
  },
  children: [
    {
      path: "/file/admin",
      name: "fileTitle",
      component: () => import("/@/views/file-admin/index.vue"),
      meta: {
        title: $t("文件导出"),
        showParent: true,
        roles: ["file.export"]
      }
    },
    // {
    //   path: "/file/import",
    //   name: "fileImport",
    //   component: () => import("/@/views/file-admin/import.vue"),
    //   meta: {
    //     title: $t("文件导入"),
    //     showParent: true,
    //     authority: ["export.page"]
    //   }
    // }
  ]
};

export default fileRouter;
