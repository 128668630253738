import { $t } from "/@/plugins/i18n";
const Layout = () => import("/@/layout/index.vue");

const dataBoardRouter = {
  path: "/",
  name: "DashBoard",
  component: Layout,
  redirect: "/data-board",
  meta: {
    icon: "6",
    title: $t("数据"),
    rank: 1
  },
  children: [
    {
      path: "/data-board",
      name: "DataBoard",
      component: () => import("/@/views/data-board/index.vue"),
      meta: {
        title: $t("数据看板"),
        showParent: false
      }
    },
    {
          path: "/transaction-analysis/data-report",
          name: "DataReport",
          component: () => import("/@/views/data-report/index.vue"),
          meta: {
            title: $t("经营报告"),
            showParent: true,
            roles: ["koms.report"]
          }
        },
  ]
};

export default dataBoardRouter;
