import { $t } from "/@/plugins/i18n";
import $store from "/@/store/index";
const Layout = () => import("/@/layout/index.vue");

const homeRouter = {
  path: "/",
  component: Layout,
  redirect: "/data-board",
  meta: {
    icon: "1",
    title: $t("工作台"),
    rank: 0
  },
  children: [
    {
      path: "/data-board",
      component: () => import("/@/views/welcome.vue"),
      meta: {
        title: $t("首页"),
      }
    }
  ]
};

export default homeRouter;
