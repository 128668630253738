import { $t } from "/@/plugins/i18n";
const Layout = () => import("/@/layout/index.vue");

const orderRouter = {
  path: "/order",
  name: "order",
  component: Layout,
  redirect: "/order/admin",
  meta: {
    icon: "pay",
    title: "支付",
    rank: 3,
    roles: ["order","koms.no-card-present"]
  },
  // hiddenChild: true, // 导航不显示下级
  children: [
    {
      path: "/order/admin",
      name: "orderAdmin",
      component: () => import("/@/views/order/index.vue"),
      meta: {
        title: $t("订单管理"),
        showParent: true,
        roles: ["order"]
      }
    },
    {
      path: "/order/add",
      name: "OrderAdd",
      component: () => import("/@/views/order/add-or-edit.vue"),
      meta: {
        title: $t("新增"),
        keepAlive: true,
        showLink: false,
        authority: ["order"]
      }
    },
    {
      path: "/order/detail",
      name: "OrderDetail",
      component: () => import("/@/views/order/detail.vue"),
      meta: {
        title: $t("详情"),
        keepAlive: true,
        showLink: false,
        authority: ["order"],
        showParent: false
      }
    },
    {
      path: "/configure/admin",
      name: "configureAdmin",
      component: () => import("/@/views/configure/index.vue"),
      meta: {
        title: $t("支付样式配置"),
        showParent: true,
        roles: ["koms.no-card-present"]
      }
    },
  ]
};

export default orderRouter;
