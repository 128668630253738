{
  # 商户平台
  # 数据看板
  "数据看板": "数据看板",
  "今日实时概况": "今日实时概况",
  "(按自然日统计)": "(按自然日统计)",
  "更新": "更新",
  "门店数量": "门店数量",
  "终端数量": "终端数量",
  "活跃门店": "活跃门店",
  "活跃终端数": "活跃终端数",
  "商户数量": "商户数量",
  "消费笔数占比": "消费笔数占比",
  "消费金额占比": "消费金额占比",
  "消费笔数趋势": "消费笔数趋势",
  "消费笔数：": "消费笔数：{value}",
  "消费金额趋势": "消费金额趋势",
  "消费金额：": "消费金额：{value}",
  "笔数": "笔数",
  "占比": "占比",
  "机构消费笔数占比": "机构消费笔数占比",
  "金额": "金额",
  "机构消费金额占比": "机构消费金额占比",
  "商户拥有的门店总数": "商户拥有的门店总数",
  "商户拥有的终端总数": "商户拥有的终端总数",
  "当日产生交易的门店数量": "当日产生交易的门店数量",
  "当日产生交易的终端数量": "当日产生交易的终端数量",
  "商户支持的所有交易币种": "商户支持的所有交易币种",
  # 数据报表
  "报表数据": "报表数据",
  "日期": "日期",
  "消费金额高峰时段分析": "消费金额高峰时段分析",
  "时段名称": "时段名称",
  "时段区间": "时段区间",
  "当天": "当天",
  "上一天": "上一天",
  "上周同天": "上周同天",
  "本周": "本周",
  "上周": "上周",
  "本月": "本月",
  "当月": "当月",
  "上月": "上月",
  "去年同月": "去年同月",
  "较上周同天": "较上周同天",
  "较上月": "较上月",
  "较上周": "较上周",
  "较昨日": "较昨日",
  "较去年同月": "较去年同月",
  "交易日排行": "交易日排行",
  "消费金额排行": "消费金额排行",
  "更多": "更多",
  "前10排名": "前10排名",
  "排名变化": "排名变化",
  "商户名称": "商户名称",
  "门店排行": "门店排行",
  "前5排名": "前5排名",
  "门店名称": "门店名称",
  "终端排行": "终端排行",
  "终端ID": "终端ID",
  "消费笔数高峰时段分析": "消费笔数高峰时段分析",
  "消费笔数排行": "消费笔数排行",
  "高峰时段": "高峰时段",
  "消费笔数": "消费笔数",
  "所有正向消费的总金额": "所有正向消费的总金额",
  "所有正向消费的总笔数": "所有正向消费的总笔数",
  "退货金额": "退货金额",
  "=消费金额/消费笔数": "=消费金额/消费笔数",
  "第某周": "第{week}周",
  "所有负向退货的总金额": "所有负向退货的总金额",
  "退货笔数": "退货笔数",
  "所有负向退货的总笔数": "所有负向退货的总笔数",
  "暂无数据": "暂无数据",
  "关闭": "关闭",
  "周一": "周一",
  "周二": "周二",
  "周三": "周三",
  "周四": "周四",
  "周五": "周五",
  "周六": "周六",
  "周日": "周日",
  "星期一": "星期一",
  "星期二": "星期二",
  "星期三": "星期三",
  "星期四": "星期四",
  "星期五": "星期五",
  "星期六": "星期六",
  "星期日": "星期日",
  "日报": "日报",
  "选择日期": "选择日期",
  "此处为结算日期": "此处为结算日期",
  "请选择日期": "请选择日期",
  "选择币种": "选择币种",
  "请选择": "请选择",
  "重置": "重置",
  "查询": "查询",
  "周报": "周报",
  "请选择周": "请选择周",
  "月报": "月报",
  "请选择月": "请选择月",
  "交易概况": "交易概况",
  "KPI概况": "KPI概况",
  "交易趋势": "交易趋势",
  "消费金额": "消费金额",
  "消费趋势分析": "消费趋势分析",
  "查看详情": "查看详情",
  "月度交易金额详情": "月度交易金额详情",
  "月度交易笔数详情": "月度交易笔数详情",
  "消费单均价": "消费单均价",
  "消费单均价趋势": "消费单均价趋势",
  "月度交易单均价详情": "月度交易单均价详情",
  "请先输入查询条件": "请先输入查询条件",
  "当天消费金额": "当天消费金额",
  "当月消费金额": "当月消费金额",
  "本周消费金额": "本周消费金额",
  "上一天消费金额": "上一天消费金额",
  "上月消费金额": "上月消费金额",
  "上周消费金额": "上周消费金额",
  "当天消费笔数": "当天消费笔数",
  "当月消费笔数": "当月消费笔数",
  "本周消费笔数": "本周消费笔数",
  "上一天消费笔数": "上一天消费笔数",
  "上月消费笔数": "上月消费笔数",
  "上周消费笔数": "上周消费笔数",
  "当月消费单均价": "当月消费单均价",
  "上月消费单均价": "上月消费单均价",
  "暂未完成对账，无法输出数据，请耐心等待！": "暂未完成对账，无法输出数据，请耐心等待！",
  "请填写所有查询条件": "请填写所有查询条件",
  # 退货记录
  "商户交易订单号": "商户交易订单号",
  "类型": "类型",
  "商户标识": "商户标识",
  "终端标识": "终端标识",
  "厂商": "厂商",
  "机型": "机型",
  "设备S/N": "设备S/N",
  "交易名称": "交易名称",
  "币种": "币种",
  "状态": "状态",
  "交易时间": "交易时间",
  "操作员": "操作员",
  "操作": "操作",
  "支付渠道": "支付渠道",

  "时间": "时间",
  "开始日期": "开始日期",
  "结束日期": "结束日期",
  "网关支付": "网关支付",
  "非网关支付": "非网关支付",

  "渠道处理中": "渠道处理中",
  "系统处理中": "系统处理中",
  "重发": "重发",
  "提交": "提交",
  "交易币种": "交易币种",

  "取消": "取消",

  "确认": "确认",

  "请输入商户交易订单号": "请输入商户交易订单号",
  "商户订单号/商户标识/商户名称/终端标识/SN": "商户订单号/商户标识/商户名称/终端标识/SN",
  "全部": "全部",
  "成功": "成功",
  "失败": "失败",
  "处理中": "处理中",


  "正常": "正常",
  "已冲正": "已冲正",
  "已撤销": "已撤销",


  "clear":
    {
      "number": "商户交易订单号",
      "name": "商户名称",
      "clearList": "清算信息",
      "clearTotal": "结算汇总",
      "mid": "商户标识（PMR）",
      "tid": "终端标识（TID）",
      "sn": "设备S/N",
      "trade": "交易名称",
      "currency": "币种",
      "total1": "交易总额",
      "amount1": "交易金额",
      "premium": "手续费",
      "total2": "清算总额",
      "amount2": "清算净额",
      "time": "交易时间",
      "person": "清算方",
      "day": "清算日期",
      "search": "搜索商户名称/PMR/TID/SN"
    },
  "accountCenter":
    {
      "name": "用户中心",
      "user": "用户管理",
      "edit": "编辑用户",
      "add": "新增用户",
      "detail": "用户详情",
      "userName": "用户名",
      "names": "姓名",
      "names1": "角色名",
      "creator": "创建人",
      "gmtCreate": "创建时间",
      "userNames": "用户名",
      "email": "邮箱",
      "phone": "电话",
      "type": "状态",
      "role": "角色管理",
      "roleName": "角色名",
      "roleDetail": "角色详情",
      "addRole": "新增角色",
      "lastTime": "最后一次登录",
      "upTime": "上次登录时间",
      "upIP": "上次登录IP",
      "super": "超管",
      "unSuper": "非超管",
      "normal": "正常",
      "role1": "商户管理员",
      "role2": "门店管理员",
      "choose": "选择门店",
      "start": "开始日期",
      "end": "结束日期",
      "max64": "最长64位",
      "max32": "最长32位",
      "max20": "6-20位",
      "max100": "最长100位",
      "max128": "最长128位",
      "export1": "导出",
      "export2": "是否导出？",
      "error1": "请输入正确的邮箱",
      "error2": "请输入正确的手机号",
      "error3": "请输入大小写字母数字以及下划线"
    },

  "ok": "保存",
  "cancel": "返回",
  "confirm": "确定",
  "no": "取消",
  "close": "关闭",
  "save": "保存",
  "success": "操作成功",
  "success2": "已复制到剪切板",
  "input": "请输入",
  "select": "请选择",
  "add": "新增",
  "edt": "编辑",
  "del": "删除",
  "reset": "重置",
  "freeze": "冻结",
  "unfreeze": "解冻",
  "Inquire": "查询",
  "operate": "操作",
  "checkAll": "全选",
  "showPwd": "保存密码",
  "showPwd2": "请妥善保管您的密码",

  # navbar
  "profile": "个人中心",
  "out": "退出登录",
  "商户管理中心": "商户管理中心",
  "门店管理中心": "门店管理中心",
  "切换成中文": "切换成中文",
  "切换成英文": "切换成英文",
  "outTime": "请求超时，请重试",
  "温馨提示": "温馨提示",
  "需要您重新登录！": "需要您重新登录！",
  "首页": "首页",
  "交易分析": "交易分析",
  "经营报告": "经营报告",
  "交易流水": "交易流水",

  "选择币种：": "选择币种：",
  "当前默认": "当前默认",
  "设为默认币种": "设为默认币种",
  "点击折叠": "点击折叠",
  "点击展开": "点击展开",
  "关联交易": "关联交易",

  "门店名称...": "门店名称...",

  "订单不存在": "订单不存在",
  "是否重置用户登录密码": "是否重置用户登录密码",
  "暂无操作权限": "暂无操作权限",
  "较上一天": "较上一天",

  # 新增
  "是否解冻": "是否解冻？",
  "是否冻结": "是否冻结？",
  "门店操作员": "门店操作员",
  "用户已冻结": "用户已冻结",
  "用户已启用": "用户已启用",
  "清算汇总": "清算汇总",
  "启用中": "启用中",
  "已停用": "已停用",
  "已注销": "已注销",
  "号": "号",
  "交易日": "交易日",
  "星期": "星期",
  "排名": "排名",
  "交易日消费金额排行": "交易日消费金额排行",
  "交易日消费笔数排行": "交易日消费笔数排行",
  "终端消费笔数排行": "终端消费笔数排行",
  "商户管理员": "商户管理员",
  "门店管理员": "门店管理员",
  "门店超级管理员": "门店超级管理员",
  "商户超级管理员": "商户超级管理员",
  "汇总详情": "汇总详情",

  "支持币种": "支持币种",
  "响应超时，请稍后再试": "响应超时，请稍后再试",
  "返回登录": "返回登录",

  "是否发起查询": "是否发起查询?",
  "token失效或用户暂无权限，请确认后重试": "token失效或用户暂无权限，请确认后重试",
  "原商户订单号/商户标识/商户名称/终端标识/SN": "原商户订单号/商户标识/商户名称/终端标识/SN",
  "原商户订单号/商户标识/商户名称/终端标识": "原商户订单号/商户标识/商户名称/终端标识",
  "重新登录": "重新登录",
  "结算信息": "结算信息",
  "订单号不存在": "订单号不存在",
  "登录超时": "登录超时",
  "八达通": "八达通",
  "Octopus": "八达通",
  "手机": "手机",
  "请输入原商户交易订单号": "请输入原商户交易订单号",
  "商户不存在": "商户不存在",
  "门店不存在": "门店不存在",
  "八达通支付": "八达通支付",
  "八达通退款": "八达通退款",
  "八达通撤销": "八达通撤销",
  "八达通查询": "八达通查询",
  "更新密钥": "更新密钥",
  "交换数据": "交换数据",
  "用户名已存在": "用户名已存在",
  "手机号码已存在": "手机号码已存在",
  "手机号已存在": "手机号已存在",
  "邮箱已存在": "邮箱已存在",
  "请输入正数": "请输入正数",
  "请输入正确格式": "请输入正确格式",
  "回到顶部": "回到顶部",
  "对账未完成": "对账未完成",
  "返回": "返回",

  # 新增
  "是": "是",
  "否": "否",

  "确认分期计划": "确认分期计划",
  "获取分期计划": "获取分期计划",
  "分期状态": "分期状态",
  "分期计划ID": "分期计划ID",
  "确认分期ID": "确认分期ID",
  "分期期数": "分期期数",
  "还款周期": "还款周期",
  "分期总额": "分期总额",
  "首期本金": "首期本金",
  "首期手续费": "首期手续费",
  "末期本金": "末期本金",
  "末期手续费": "末期手续费",
  "分期总手续费": "分期总手续费",
  "最多两位小数": "最多两位小数",
  "正在努力加载中...": "正在努力加载中...",

  "max17": "最长17位",
  "max48": "最长48位",
  "max1024": "最长1024位",

  # 在线订单
  "订单管理": "订单管理",
  "Online Order": "Online Order",
  "System Reference No.": "System Reference No.",
  "Bill to": "Bill to",
  "Order No.": "Order No.",
  "Order Status": "Order Status",
  "Order Amount": "Order Amount",
  "Creation Time": "Creation Time",
  "展开": "展开",
  "收拢": "收拢",

  "请输入金额": "请输入金额",
  "请输入(不包含特殊字符)": "请输入(不包含特殊字符)",
  "是否删除用户": "是否删除用户？",

  "修改初始密码": "修改初始密码",
  "您已成功登录Klond 商户服务平台，为保证您的账号安全，您需要修改初始密码": "您已成功登录Klond 商户服务平台，为保证您的账号安全，您需要修改初始密码",
  "新密码": "新密码",
  "请输入新密码": "请输入新密码",
  "确认密码": "确认密码",
  "请确认密码": "请确认密码",
  "格式错误": "格式错误",
  "请再次输入新密码": "请再次输入新密码",
  "两次输入不一致": "两次输入不一致",
  "密码修改成功": "密码修改成功",
  "正在跳转至登录界面": "正在跳转至登录界面",
  "密码最小长度为8位": "密码最小长度为8位",
  "密码最大长度为16位": "密码最大长度为16位",
  "必须包含字母、数字和特殊符号至少两种": "必须包含字母、数字和特殊符号至少两种",
  "可使用特殊字符": "可使用特殊字符",
  "请输入": "请输入",
  "vPlanRef": "Plan Ref",
  # "externalTransactionRef": "External Transaction Ref",
  "externalTransactionRef": "分期MID",
  "分期MID": "分期MID",
  "vPlanID": "Plan ID",
  "vPlanAcceptanceId": "PlanAcceptance ID",

  "待审核": "待审核",
  "审核驳回": "审核驳回",
  "审核通过": "审核通过",
  "渠道": "渠道",
  "获取时区失败": "获取时区失败",
  "outtransactionno": "流水号",
  "原商户订单号/商户标识/商户名称/终端标识/流水号/分期MID": "原商户订单号/商户标识/商户名称/终端标识/流水号/分期MID",
  "导出": "导出",
  "是否导出流水数据": "是否导出流水数据",
  "生成中，请在文件管理查看文件": "生成中，请在文件管理查看文件",
  "清除全部": "清除全部",
  "清除": "清除",
  "是否清除全部记录": "是否清除全部记录",
  "文件管理": "文件管理",
  "文件导出": "文件导出",
  "文件名称": "文件名称",
  "导出时间": "导出时间",
  "文件状态": "文件状态",
  "下载": "下载",
  "是否下载文件": "是否下载文件",
  "生成成功": "生成成功",
  "生成失败": "生成失败",
  "delete": "删除",
  "delete_role_tip": "是否删除角色？",

  "收件人/订单金额/订单号/支付单号": "收件人/订单金额/订单号/支付单号",
  "收件人": "收件人",
  "订单金额": "订单金额",
  "订单号": "订单号",
  "支付单号": "支付单号",
  "支付成功": "支付成功",
  "待支付": "待支付",
  "支付中": "支付中",
  "支付失败": "支付失败",
  "创建时间": "创建时间",
  "新增": "新增",
  "邮件模板": "邮件模板",
  "您有一封待支付的账单，账单金额为 ": "您有一封待支付的账单，账单金额为 ",
  "账单信息": "账单信息",
  "订单编号": "订单编号",
  "最晚付款时间": "最晚付款时间",
  "订单描述": "订单描述",
  "订单详情": "订单详情",
  "收款方信息": "收款方信息",
  "商户地址": "商户地址",
  "定义商家LOGO": "定义商家LOGO",
  "上传证件": "上传图片",
  "付款人": "付款人",
  "邮箱": "邮箱",
  "附件": "附件",
  "上传附件": "上传附件",
  "支持扩展名": "支持扩展名",
  "用于订单有效性，超过该时间，订单将失效不可支付": "用于订单有效性，超过该时间，订单将失效不可支付",
  "付款信息": "付款信息",
  "选择文件": "选择文件",
  "JPG或PNG格式!": "JPG或PNG格式!",
  "大小不超过2MB!": "大小不超过2MB!",
  "是否导出数据": "是否导出数据?",
  "订单地址": "订单地址",
  "已复制到剪切板": "已复制到剪切板",
  "商家LOGO": "商家LOGO",
  "支付信息": "支付信息",
  "支付时间": "支付时间",
  "商户号": "商户标识",
  "交易类型": "交易类型",
  "支付状态": "支付状态",
  "详情": "详情",


  "KlondPay商户服务平台": "KlondPay商户服务平台",
  "数据看板-汇总指标": "数据看板-汇总指标",
  "查询消费金额、笔数占比": "查询消费金额、笔数占比",
  "数据看板-交易趋势": "数据看板-交易趋势",
  "数据看板-基本信息饼图": "数据看板-基本信息饼图",
  "清算信息": "清算信息",
  "结算汇总": "结算汇总",
  "清结算分页": "清结算分页",
  "导出清算明细": "导出清算明细",
  "清结算汇总详情": "清结算汇总详情",
  "查询清结算汇总": "查询清结算汇总",
  "分页查询流水": "分页查询流水",
  "根据商户交易订单号获取交易详情": "根据商户交易订单号获取交易详情",
  "关联商户交易订单号": "关联商户交易订单号",

  "流水导出": "流水导出",
  "日报高峰时刻交易金额": "日报高峰时刻交易金额",
  "日报高峰时刻交易笔数": "日报高峰时刻交易笔数",
  "Kpi概况": "Kpi概况",
  "门店消费金额排行": "门店消费金额排行",
  "门店消费笔数排行": "门店消费笔数排行",
  "终端消费金额排行": "终端消费金额排行",
  "终端消费数量排行": "终端消费数量排行",
  "交易金额趋势,交易笔数趋势,单均价趋势": "交易金额趋势,交易笔数趋势,单均价趋势",
  "账户中心": "账户中心",
  "用户管理": "用户管理",
  "新增用户": "新增用户",
  "修改用户": "修改用户",
  "获取用户列表": "获取用户列表",
  "启用用户": "启用用户",
  "冻结用户": "冻结用户",
  "重置用户密码": "重置用户密码",
  "角色管理": "角色",
  "获取角色列表": "获取角色列表",
  "新增角色": "新增角色",
  "删除角色": "删除角色",
  "修改角色": "修改角色",
  "角色详情": "角色详情",
  "查询导出列表": "查询导出列表",
  "删除导出历史": "删除导出历史",
  "角色名称": "角色名称",
  "在线订单": "在线订单",
  "查看订单详情": "查看订单详情",
  "新增在线订单": "新增在线订单",
  "查看订单列表": "查看订单列表",
  "总记录数": "总记录数",
  "微信支付": "微信支付",
  "支付宝支付": "支付宝支付",
  "原商户交易订单号": "原商户交易订单号",
  "大小不超过10MB!": "大小不超过10MB!",
  "一次只能上传一个文件": "一次只能上传一个文件",

  "已失效": "已失效",
  "结算管理": "结算管理",
  "结算明细": "结算明细",

  "终端号(TID)": "终端标识(TID)",
  "商户号(PMR)": "商户标识(PMR)",
  "结算金额": "结算金额",
  "商户费率": "商户费率",
  "交易手续费": "交易手续费",
  "结算净额": "结算净额",
  "结算日期": "结算日期",
  "操作成功": "操作成功",
  "是否重发邮件": "是否重发邮件?",
  "结算汇总列表": "结算汇总列表",
  "结算汇总币种列表": "结算汇总币种列表",
  "结算汇总导出": "结算汇总导出",
  "结算明细列表": "结算明细列表",
  "结算明细币种列表": "结算明细币种列表",
  "结算明细导出": "结算明细导出",
  "重发邮件": "重发邮件",
  "共": "共",
  "条分页": "条",
  "请先添加终端号": "请先添加终端号",

  "商户超级管理员(koms)": "商户超级管理员(koms)",
  "门店超级管理员(koms)": "门店超级管理员(koms)",
  "商户管理员(koms)": "商户管理员(koms)",
  "门店管理员(koms)": "门店管理员(koms)",
  "门店操作员(koms)": "门店操作员(koms)",
  "订单状态": "订单状态",
  "生效中": "生效中",
  "正数，整数最多8位，小数最多2位": "正数，整数最多8位，小数最多2位",
   "流水详情": "流水详情",
   "请输入正确的邮箱": "请输入正确的邮箱",
   "导入与导出": "导入与导出",
   "删除用户": "删除用户",
   "失效": "失效",
   "klondPay商户服务平台": "KlondPay商户服务平台",
   "分页查询用户": "分页查询用户",
   "重置密码": "重置密码",
   "用户中心": "用户中心",
   "交易流水详情": "交易流水详情",
   "交易日志详情": "交易日志详情",
   "原商户订单号": "原商户订单号",

   "补录款": "补录款",
   "冲销款": "冲销款",
   "款项": "款项",

   "原交易详情": "原交易详情",
   "冲销": "冲销",
   "款项类型": "款项类型",
   "按商户时区过滤": "按商户时区过滤",
   "按渠道时区过滤": "按渠道时区过滤",
   "大小不超过1MB!": "大小不超过1MB!",

   "支付样式配置": "支付样式配置",
   "（限制1M以内）": "（限制1M以内）",
   "上传背景图": "上传背景图",
   "上传Logo": "上传Logo",
   "1.您在此配置的支付界面样式（如按钮文字、颜色等）将会应用到整个支付流程，包括支付结果页面的按钮和提示文字。": "1.您在此配置的支付界面样式（如按钮文字、颜色等）将会应用到整个支付流程，包括支付结果页面的按钮和提示文字。",
   "2.发布后，将立即更新支付界面的样式，请谨慎操作。": "2.发布后，将立即更新支付界面的样式，请谨慎操作。",
   "（建议尺寸1920*1080，限制2M以内）": "（建议尺寸1920*1080，限制2M以内）",
   "按钮名称": "按钮名称",
   "按钮配色": "按钮配色",
  "发布": "发布",
  "渠道结算明细": "渠道结算明细",
  "请上传Logo！": "请上传Logo！",

  "商户信息": "商户信息",
  "基础信息": "基础信息",
  "所属机构": "所属机构",
  "联系人": "联系人",
  "联系电话": "联系电话",
  "联系邮箱": "联系邮箱",
  "业务地区": "业务地区",
  "注册地址": "注册地址",
  "交易时区": "交易时区",
  "代办人": "代办人",
  "服务商": "服务商",
  "开通产品": "开通产品",
  "本页面根据渠道时区展示每个交易日的商户结算明细数据。": "本页面根据渠道时区展示每个交易日的商户结算明细数据。",
  "支付样式": "支付样式",
  "3~15位": "3~15位",
  "请定义商户名称": "请定义商户名称",
  "上传图片": "上传图片",
  "银行卡在线支付": "银行卡在线支付",
  "扫码支付": "扫码支付",
  "VISA分期": "VISA分期",


  "查询渠道明细列表": "查询渠道明细列表",
  "查询渠道币种汇总": "查询渠道币种汇总",
  "查询渠道明细导出": "查询渠道明细导出",
  "角色被使用，无法删除": "角色被使用，无法删除",
  "卡号": "卡号",
  "卡品牌": "卡品牌",
  "卡类型": "卡类型",
  "发卡机构": "发卡机构",
  "上级商户名称": "上级商户名称",
  "列表数据": "列表数据",


  # 退款
  "已退款": "已退款",
  "部分退款": "部分退款",
  "该订单不支持退款": "该订单不支持退款",
  "分期退款": "分期退款",
  "已经撤销的交易无法发起退款": "已经撤销的交易无法发起退款",
  "退款成功": "退款成功",
  "退款失败": "退款失败",
  "分页查询退款订单列表": "分页查询退款订单列表",
  "查询退款结果": "查询退款结果",
  "重新发起退款申请": "重新发起退款申请",
  "提交退款申请": "提交退款申请",




  # 退货
  "退款记录": "退款记录",
  "退款": "退款",
  "退款申请提交成功": "退款申请提交成功",
  "退款申请提交失败，请重试": "退款申请提交失败，请重试",
  "可退款金额": "可退款金额",
  "是否重新发起退款": "是否重新发起退款",
  "请输入退款金额": "请输入退款金额",
  "重新发起退款": "重新发起退款",
  "退款失败，请重试": "退款失败，请重试",
  "输入金额不能大于可退款金额": "输入金额不能大于可退款金额",
  "当前已有退款申请在处理中": "当前已有退款申请在处理中",
  "退款记录详情": "退款记录详情",
  "退款详情": "退款详情",
  "退款金额": "退款金额",
  "退款笔数": "退款笔数",

  # VISA
  "退款记录 (VISA)": "退款记录 (VISA)",
  "VISA退款记录详情": "退款记录详情",
  "VISA退款": "退款",
  "VISA退款成功": "退款成功",
  "VISA退款失败": "退款失败",
  "VISA退款详情": "退款详情",
  "VISA重新发起退款": "重新发起退款",
  "VISA可退款金额": "可退款金额",
  "VISA退款金额": "退款金额",
  "VISA退款申请提交成功": "退款申请提交成功",
  "VISA请输入退款金额": "请输入退款金额",
  "VISA输入金额不能大于可退款金额": "输入金额不能大于可退款金额",

  "VISA是否重新发起退款": "是否重新发起退款",
  "VISA已退款": "已退款",
  "VISA部分退款": "部分退款",
  "请选择权限": "请选择权限",
  "交易金额": "交易金额",
  "退出系统": "退出系统",
  "修改密码": "修改密码",
  "码牌ID": "码牌ID",
  "数据": "数据",
  "支付": "支付",
  "交易": "交易",
  "报表": "报表",
  "用户": "用户",
  "文件": "文件",
  "编辑": "编辑",
  "userTitle": "用户管理",
  "roleTitle": "角色管理",
  "用户标识": "用户标识",
  "用户登录名": "用户登录名",
  "多币种展开": "多币种展开",
  "多币种收起": "多币种收起",
  "复制": "复制",



}
