import { $t } from "/@/plugins/i18n";
const Layout = () => import("/@/layout/index.vue");

const transactionFlowRouter = {
  path: "/transaction-flow",
  component: Layout,
  redirect: "/transaction-flow/index",
  meta: {
    icon: "trans",
    title: $t("交易"),
    rank: 7,
    roles: ["koms.trans",'koms.refund']
  },
  children: [
    {
      path: "/transaction-flow/index",
      name: "transactionFlow",
      component: () => import("/@/views/transaction-flow/index.vue"),
      meta: {
        title: $t("交易流水"),
        showParent: true,
        roles: ["koms.trans"]
      },
      children: [
        {
          path: "/transaction-flow/index/detail",
          name: "transactionFlowDetail",
          component: () => import("/@/views/transaction-flow/detail.vue"),
          meta: {
            title: $t("详情"),
            showParent: true,
            showLink: false
            // roles: ["dop.home"]
          }
        }
      ]
    },
    {
      path: "/transaction-flow/ret-record",
      name: "retRecord",
      component: () => import("/@/views/ret-record/index.vue"),
      meta: {
        title: $t("退款记录"),
        // keepAlive: true,
        showParent: true,
        roles: ["koms.refund"]
      },
    },
    {
      path: "/transaction-flow/ret-record-visa",
      name: "retRecordVisa",
      component: () => import("/@/views/ret-record-visa/index.vue"),
      meta: {
        title: $t("退款记录 (VISA)"),
        showParent: true,
        roles: ["koms.refund"]
      },
    }
  ]
};

export default transactionFlowRouter;
